import fetch from '@/utils/fetch.js';

//升级代理
export function agentUp(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/agent/up",
		method: 'POST',
		data
	})
}
//代理信息
export function agentInfo(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/agent/info",
		method: 'POST',
		data
	})
}
//代理用户列表
export function invitationList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/agent/invitation/list",
		method: 'POST',
		data
	})
}
//代理收益
export function rarning(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/agent/rarning",
		method: 'POST',
		data
	})
}
//代理收益列表
export function rarningList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/agent/rarning/list",
		method: 'POST',
		data
	})
}