<template>
	<div class="myEarning">
		<div class="title">
			{{i18n('87')}}
		</div>
		<div class="inner">
			<div class="one">
				<div class="tit">
					<div class="left">
						{{i18n('273')}}
					</div>
					<div class="right">

					</div>
				</div>
				<earningYes></earningYes>
			</div>
			<div class="list">
				<div class="listItem tableTitle">
					<div class="item">
						{{i18n('276')}}
					</div>
					<div class="item">
						{{i18n('277')}}
					</div>
				</div>
				<div class="listItem" v-for="(item,index) in listData" :key="index">
					<div class="item">
						{{item.nickname}}
					</div>
					<div class="item">
						{{item.create_time*1000 | formatTime('YMDHMS')}}
					</div>
				</div>
				<div class="pagination" v-if="total!=0">
					<el-pagination layout="prev, pager, next" :total="total" :page-size="params.page_size"
						:current-page='params.page_num' @current-change="currentChange">
					</el-pagination>
				</div>
				<div class="noData" v-if="noData">
					<div class="">
						<img src="@/assets/noData2.png" alt="">
						<div class="text">
							{{i18n('278')}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		invitationList
	} from "@/api/agent.js"

	import earningYes from "./components/earningYes.vue"
	export default {
		name: "myEarning",
		components: {
			earningYes
		},
		data() {
			return {
				params: {
					user_id: this.$store.state.user.userInfo.user_id,
					page_num: 1,
					page_size: 5
				},
				noData: false,
				total: 0,
				listData:[]
			}
		},
		methods: {
			//获取用户列表
			async rarningList() {
				let {
					data,
					total
				} = await invitationList(this.params)
				this.listData = data
				this.total = total
				if (total == 0) {
					this.noData = true
				} else {
					this.noData = false
				}
			},
			currentChange(page_num) {
				this.params.page_num = page_num
				this.rarningList()
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		created() {
			this.$store.commit('user/setClassifyShow',false)
			this.rarningList()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.noData {
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.noData img {
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}

	.noData .text {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}

	.list {
		width: 1032px;
		padding: 0 24px 0 24px;
		background: #FFFFFF;
		border-radius: 12px;
		box-sizing: border-box;
	}

	.list .listItem {
		background: rgba(86, 171, 123, 0.10);
		cursor: pointer;
	}

	.list .listItem.listItem:nth-child(2n-1) {
		background: #fff;
	}

	.listItem {
		display: flex;
		align-items: center;
		height: 46px;
		border-bottom: 1px solid rgba(241, 241, 241, 1);
	}

	.listItem .item {
		flex: 1;
		text-align: center;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #1D2129;
		text-align: center;
		font-weight: 400;
	}

	.tableTitle .item {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		font-weight: 400;
	}

	.one {
		width: 1032px;
		background: #FFFFFF;
		border-radius: 12px;
		margin-bottom: 16px;
	}

	.tit {
		padding: 0 24px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #56AB7B;
	}

	.tit .left {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
	}

	.tit .right {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		text-align: right;
		font-weight: 500;
	}

	.inner {
		flex: 1;
		overflow-y: scroll;
	}

	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
	}

	.myEarning {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	>>>.el-pagination .btn-next {
		background: none;
	}
	
	>>>.el-pagination button:disabled {
		background: none;
	}
	
	>>>.el-pagination .btn-prev {
		background: none;
	}
	
	>>>.el-pager li.active {
		color: #56AB7B;
	}
	
	.pagination {
		width: 100%;
		height: 36px;
		padding-bottom: 24px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
