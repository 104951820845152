<template>
	<div class="earningYes">
		<div class="item">
			<div class="inn">
				<div class="" style="color: #F23725;">
					<span>₱</span>{{rarningData.today}}
				</div>
				<div class="">
					{{i18n('106')}}
				</div>
			</div>
		</div>
		<div class="item">
			<div class="inn">
				<div class="">
					<span>₱</span>{{rarningData.total}}
				</div>
				<div class="">
					{{i18n('254')}}
				</div>
			</div>
		</div>
		<div class="item">
			<div class="inn">
				<div class="">
					<span>₱</span>{{rarningData.yesterday}}
				</div>
				<div class="">
					{{i18n('255')}}
				</div>
			</div>
		</div>
		<div class="item">
			<div class="inn">
				<div class="">
					<span>₱</span>{{rarningData.month}}
				</div>
				<div class="">
					{{i18n('256')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		rarning
	} from "@/api/agent.js"
	export default {
		name: "earningYes",
		data() {
			return {
				rarningData: {
					total: 0,
					today: 0,
					yesterday: 0,
					month: 0
				}
			}
		},
		methods: {
			
		},
		async created() {
			let {
				data
			} = await rarning()
			this.rarningData = data
		},
		mounted() {
	
		}
	};
</script>

<style scoped>
	.earningYes{
		padding: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.earningYes .item{
		width: 236px;
		height: 140px;
		background: rgba(86,171,123,0.10);
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.earningYes .item .inn div:nth-child(1){
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		text-align: center;
		line-height: 28px;
		font-weight: 500;
	}
	.earningYes .item .inn div:nth-child(1) span{
		font-size: 12px;
		padding-right: 3px;
	}
	.earningYes .item .inn div:nth-child(2){
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #56AB7B;
		text-align: center;
		font-weight: 400;
		padding-top: 10px;
	}
</style>